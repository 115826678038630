import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';
import './home.css';
import Row from '../../components/Row';
import RowYoucan from '../../components/RowYoucan';
import { useSearchParams } from 'react-router-dom';
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from 'react-icons/fa';




const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [items, setItems] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [showArchived, setShowArchived] = useState(searchParams.get('status')==="archived"? true: false); // New state to toggle archived orders
  const [isLoading, setIsLoading] = useState(false)
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(parseInt(searchParams.get('page'), 10) || 1)
  const [countOrdres, setCountOrders] = useState(0)

  // const page = parseInt(searchParams.get('page'), 10) || 1; 
  // const page = searchParams.get('page') || 1; 

  // const status = searchParams.get('status') || "active"; 
  // console.log('page', page);
  // console.log('page', typeof page);
  // const location = useLocation();

  // console.log(location.search); 



  const messageDisplay = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage('');
      setMessageState('');
    }, 5000);
  };

  // Fetch YouCan orders with pagination
  const fetchYouCanOrders = useCallback(
    async (page) => {
      try {
        const url = showArchived
          ? `https://om.menzbergorders.com/api/youcan-orders?page=${page}&status=archived`
          : `https://om.menzbergorders.com/api/youcan-orders?page=${page}`;
        // const url = showArchived
        //   ? `http://localhost:3000/api/youcan-orders?page=${page}&status=archived`
        //   : `http://localhost:3000/api/youcan-orders?page=${page}`;
        // console.log(url);
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Failed to fetch YouCan orders');
        }
        
        const youcanData = await response.json();
        setCountOrders(youcanData.count)
        
        const formattedYouCanData = youcanData.data.map((item) => ({
          id: item.id,
          ...item,
          checked: false,
          created_at: item.data.created_at,
          source: item.store,
        }));

        setItems((prevItems) => [
          ...prevItems.filter((item) => item.source !== 'youcan'),
          ...formattedYouCanData,
        ]);
      } catch (error) {
        console.error('Error fetching YouCan orders:', error);
      }
    },
    [showArchived]
  );

  useEffect(() => {
    fetchYouCanOrders(page);
  }, [showArchived, refresh, fetchYouCanOrders, page]);
  
  
  

  const handleCheckAll = (e) => {
    const isChecked = e.target.checked;
    setCheckAll(isChecked);

    const updatedItems = items.map((item) => ({ ...item, checked: isChecked }));
    setItems(updatedItems);
  };

  const handleItemCheck = (id) => {
    const updatedItems = items.map((item) =>{
      console.log(item)
      console.log(item.data.extra_fields["Gouvernorat 2"])

      return item.id === id ? { ...item, checked: !item.checked } : item
    });
    // console.log(id)
    setItems(updatedItems);

    const allChecked = updatedItems.every((item) => item.checked);
    setCheckAll(allChecked);
  };

  // farward all the selected items to first delivery when the button is clicked
  let deliverFn = (item)=>{
    console.log(item)
    if(item.store==="wix"){
      if(item.order.shippingInfo.title !=="Usine"){
          let client = {
              nom: `${item.order.billingInfo.contactDetails.firstName} ${item.order.billingInfo.contactDetails.lastName}`,
              gouvernerat: `${item.order.extendedFields.namespaces._user_fields.gouvernerat}`, // mch mawjoud
              ville: `${item.order.shippingInfo.logistics.shippingDestination.address.city}`,
              adresse: `${item.order.shippingInfo.logistics.shippingDestination.address.city} ${item.order.shippingInfo.logistics.shippingDestination.address.addressLine1} ${item.order.shippingInfo.logistics.shippingDestination.address.postalCode}`,
              telephone: `${item.order.shippingInfo.logistics.shippingDestination.contactDetails.phone}`,
              telephone2: "" 
          }
          // console.log('client:', client)
          let ar = ''
          let des = ''
          for (let i = 0; i <item.order.lineItems.length; i++){
              if(i===0){
                  ar = `${ar}${item.order.lineItems[i].productName.original}`
                  des = `${des}${item.order.lineItems[i].productName.original} `
                  for (let j = 0; j < item.order.lineItems[i].descriptionLines.length; j++) {
                      let txt = item.order.lineItems[i].descriptionLines[j].plainText?.original
                      // console.log(txt)
                      let color = item.order.lineItems[i].descriptionLines[j]?.color
                      const e = `${item.order.lineItems[i].descriptionLines[j].name?.original}: ${txt ? txt : ''}${color ? color : ''}`;
                      des = `${des} ${e}`
                  }
                  des = `${des} X${item.order.lineItems[i].quantity}`
              }
              else{
                  ar = `${ar} | ${item.order.lineItems[i].productName.original}`
                  des = `${des} | ${item.order.lineItems[i].productName.original} `
                  for (let j = 0; j < item.order.lineItems[i].descriptionLines.length; j++) {
                      // let desc = item.order.lineItems[i].descriptionLines[j]
                      let txt = item.order.lineItems[i].descriptionLines[j].plainText?.original
                      // console.log(txt)
                      let color = item.order.lineItems[i].descriptionLines[j]?.color
                      const e = `${item.order.lineItems[i].descriptionLines[j].name?.original}: ${txt ? txt: ''}${color ? color: ''}`;
                      des = `${des} ${e}`
                  }
                  des = `${des} X${item.order.lineItems[i].quantity}`
              }
              // products.push(pr)
          }
          let products = { // chihemou
              article: ar,
              prix: Number(item.order.priceSummary.total.amount),
              designation: des, 
              nombreArticle: item.order.lineItems.reduce((acc, product) => acc + product.quantity, 0),  
              commentaire: `${item.order.number}`,
              // nombreEchange: 0
          }
          // console.log('products', products)
          let delivery = {
                  Client: client,
                  Produit: products
              }
              // console.log(delivery)
          return delivery
      }else{
          return false
      }
    }else if(item.store === "youcan"){
      console.log('hello fucker')
      let client = {
        nom: `${item.data.customer.full_name}`,
        gouvernerat: `${item.data.extra_fields['Gouvernorat 2']}`, // gouvernerat
        // gouvernerat: `${item.data.customer.region}`, // gouvernerat
        ville: `${item.data.customer.city}`,
        adresse: `${item.data.extra_fields.Adresse}`,
        telephone: `${item.data.customer.phone}`,
        telephone2: "" 
      }
      let ar = ''
          let des = ''
          console.log(item.data.variants)
          for (let i = 0; i <item.data.variants.length; i++){
            console.log(item.data.variants[i].variant.product.name)
            console.log(item.data.variants[i].variant.variations)
              if(i===0){
                  ar = `${ar} ${item.data.variants[i].variant.product.name}`
                  des = `${des} ${item.data.variants[i].variant.product.name} `
                  // for (let j = 0; j < item.data.variants[i].variant.variations.length; j++) {
                      const variation = Object.keys(item.data.variants[i].variant.variations).map((key) => 
                        key === "default" ? `${key}` : `${key}: ${item.data.variants[i].variant.variations[key]}`
                      );
                      console.log('variation:', variation)
                      // let txt = item.data.variants[i].descriptionLines[j].plainText?.original
                      // console.log(txt)
                      // let color = item.data.variants[i].descriptionLines[j]?.color
                      // const e = `${item.data.variants[i].descriptionLines[j].name?.original}: ${txt ? txt : ''}${color ? color : ''}`;
                      des = `${des} ${variation}`
                  // }
                  des = `${des} X${item.data.variants[i].quantity}`
              }
              else{
                  ar = `${ar} | ${item.data.variants[i].variant.product.name}`
                  des = `${des} | ${item.data.variants[i].variant.product.name} `
                  // for (let j = 0; j < item.data.variants[i].variations.length; j++) {
                    const variation = Object.keys(item.data.variants[i].variant.variations).map((key) => 
                      key === "default" ? `${key}` : `${key}: ${item.data.variants[i].variant.variations[key]}`
                    );
                    // let txt = item.data.variants[i].descriptionLines[j].plainText?.original
                    // console.log(txt)
                    // let color = item.data.variants[i].descriptionLines[j]?.color
                    // const e = `${item.data.variants[i].descriptionLines[j].name?.original}: ${txt ? txt : ''}${color ? color : ''}`;
                    des = `${des} ${variation}`
                // }
                  des = `${des} X${item.data.variants[i].quantity}`
              }
              // products.push(pr)
          }
          let products = { // chihemou
              article: ar,
              prix: Number(item.data.total),
              designation: des, 
              nombreArticle: item.data.variants.reduce((acc, product) => acc + product.quantity, 0),  
              commentaire: `${item.data.ref}`,
              // nombreEchange: 0
          }
          // console.log('products', products)
          let delivery = {
              Client: client,
              Produit: products
          }
          return delivery
    }
}
const forward1Handler = async (via) => {
  setIsLoading(true);
  const selectedItems = items.filter((item) => item.checked);

  let dataToSend = [];
  selectedItems.forEach((item) => {
    let delivery = deliverFn(item);
    if (delivery !== false) {
      dataToSend.push({ dataToSend: delivery, id: item.id });
    }
  });

  let key;
  const menzberg = '209b3432-3b2b-4031-b93e-ee8c60a55558';
  const tpnt = 'f43c220d-8583-44f6-b4c2-0847408b5f39';

  if (via === 'menzberg') {
    key = menzberg;
  } else if (via === 'tpnt') {
    key = tpnt;
  }

  if (dataToSend.length > 0) {
    try {
      // fetch('http://localhost:3000/api/forward-request', {
      fetch('https://om.menzbergorders.com/api/forward-request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: dataToSend, key: key }),
      }).then((r) => r.json())
      .then((res) => {
        console.log(res)
        window.location.reload();
      })

      // const responseData = await response.json();
      // console.log('forward response:', responseData);

      // if (response.ok) {
      //   // Map through the results to display individual item statuses
      //   const successMessages = [];
      //   const errorMessages = [];

      //   responseData.forEach((result) => {
      //     if (result.status === 201) {
      //       successMessages.push(`Commande ${result.id} transmise avec succès.`);
      //     } else {
      //       errorMessages.push(`Erreur pour la commande ${result.id}: ${result.error || 'Échec de la transmission'}`);
      //     }
      //   });

      //   // Show detailed feedback in messages
      //   if (successMessages.length > 0) {
      //     setMessageState('success');
      //     messageDisplay(successMessages.join('\n'));
      //   }
      //   if (errorMessages.length > 0) {
      //     setMessageState('error');
      //     messageDisplay(errorMessages.join('\n'));
      //   }
      // } else if (response.status === 400) {
      //   setMessageState('error');
      //   messageDisplay('Une erreur est survenue lors de la transmission des commandes');
      // } else {
      //   setMessageState('error');
      //   messageDisplay('Quelque chose s\'est mal passé');
      // }
    } catch (err) {
      console.error(err);
      setMessageState('error');
      messageDisplay('Erreur de réseau: impossible de transmettre les commandes');
    }
  } else {
    setMessageState('error');
    messageDisplay(selectedItems.length === 0 ? 'Aucun élément sélectionné à transmettre' : 'Impossible de transmettre la/les commandes');
  }

  setIsLoading(false);
};


  async function handleLogout() {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error(error);
    }
  }

  const { logout } = useAuth();
  let navigate = useNavigate();

const archiveHandler = async (e) => {
  const selectedItems = items.filter((item) => item.checked);

  for (const item of selectedItems) {
      // Archive YouCan order in MySQL via backend
      try {
        await fetch(`https://om.menzbergorders.com/api/archive-youcan-order`, {
        // await fetch(`http://localhost:3000/api/archive-youcan-order`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ orderId: item.id }),
        });
      } catch (error) {
        console.error(`Failed to archive YouCan order with id ${item.id}`, error);
      }
  }

  // Update the local state to remove archived items
  setItems((prevItems) => prevItems.filter((item) => !item.checked));
};

const fulfimentHandler = async () => {
  const selectedItems = items.filter((item) => item.checked);
  if (selectedItems.length > 0) {
    await Promise.all(selectedItems.map(async (item) => {
        const response = await fetch(`https://om.menzbergorders.com/api/fulfill-order`, {
        // const response = await fetch(`http://localhost:3000/api/fulfill-order`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ orderId: item.id }),
        });
        
        const data = await response.json(); // Get the JSON data
        console.log('fulfill:', data); // Log the fulfilled response
      
    }));

    // Toggle refresh state to trigger re-fetch
    setRefresh((prev) => prev + 1);

    setMessageState('success');
    messageDisplay('Les commandes ont été marquées avec succès comme traitées');
  } else {
    setMessageState('error');
    messageDisplay("Aucun élément sélectionné à traiter");
  }
};

const handleArchiveToggle = () => {
  setShowArchived((prev) => !prev);
  setSearchParams({ page: 1, status: showArchived ? 'active' : 'archived' });
  setPage(1)
};

const handlePagination = (direction) => {
  setIsLoading(true)
  setItems([])
  const newPage = direction === 'next' ? page + 1 : page - 1;
  if (newPage < 1) return;
  setPage(newPage);
  setSearchParams({ page: newPage, status: showArchived ? 'archived' : 'active' });
  setIsLoading(false)
};


// console.log(items)
  return (
    <>{message && (
      <>
        <div className={`popup-modal ${messageState}`}>
          <p>{message}</p>
        </div>
      </>
    )}
      <div className="container">
        <div className="top">
          <div className="left">
            <h1 id="logo">Menzberg</h1>
          </div>

          <div className="right">
            <button onClick={handleLogout}>se déconnecter</button>
            <button onClick={handleArchiveToggle}>
              {showArchived ? 'commandes Active' : 'commandes archivées'}
            </button>
          </div>
        </div>

        <div className="top">
            <div className="left">
                <button onClick={()=>archiveHandler(showArchived)}>{showArchived ? "déplacer hors de l'archive" : "déplacer vers l'archive"}</button>
                <div className="pagination-controls">
                  <button onClick={() => handlePagination('prev')} disabled={page <= 1}>
                    <FaAngleLeft />
                  </button>
                  <span style={{color: 'black'}}>{page}/{Math.ceil(countOrdres/50)}</span>
                  <button onClick={() => handlePagination('next')} disabled={Math.ceil(countOrdres/50) <= page}>
                    <FaAngleRight />
                  </button>
                </div>
            </div>
            <div className="right">
                <button onClick={fulfimentHandler}>marquer comme traité</button>
                <button disabled={isLoading} onClick={() => forward1Handler('tpnt')}>Livrée TPNT</button>
                <button disabled={isLoading} className="main" onClick={() => forward1Handler('menzberg')}>Livrée Menzberg</button>
            </div>
        </div>

        <table id="ordersTable">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  name="check-all"
                  id="check-all"
                  checked={checkAll}
                  onChange={handleCheckAll}
                />
              </th>
              <th>
                <label htmlFor="check-all">Commande</label>
              </th>
              <th>Client</th>
              <th>Traitement</th>
              <th>Méthode d'expédition</th>
              <th>Total</th>
              <th>Éléments</th>
              <th>Livraison</th>
              <th>De</th>
            </tr>
          </thead>
          <tbody>
          {items
          .sort((x, y) => new Date(y.created_at) - new Date(x.created_at))         
          .map((item, i) => (
            item.source === 'wix' ? (
              <Row key={i} item={item} onItemCheck={handleItemCheck} />
            ) : (
              <RowYoucan key={i} item={item} onItemCheck={handleItemCheck} />
            )
          ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Home;
