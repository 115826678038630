import React, { useState } from 'react'
import Popup from './Popup';
import { FaChevronDown, FaChevronUp  } from "react-icons/fa";

const Row = ({item, onItemCheck }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false); 
    console.log(item)
    // console.log('row data:', item);
    const totalProducts = item.order.lineItems.reduce((acc, product) => acc + product.quantity, 0);
    const handleQuantityClick = () => {
        // console.log('clicked')
        setIsPopupOpen(prev=>!prev); // Show the popup
      };
    // const handleClosePopup = () => {
    //     setIsPopupOpen(false); // Hide the popup
    //   };
    // if()
    let client = `${item.order.billingInfo.contactDetails.firstName} ${item.order.billingInfo.contactDetails.lastName}`
    
    return (
        <tr>
            <td><input checked={item.checked} type="checkbox" className="item-checkbox" id={'item-'+item.order.number} onChange={() => onItemCheck(item.id)} /></td>
            <td><label  htmlFor={'item-'+item.order.number}>{item.id}</label></td>
                <td>{client}</td>
                {/* { item.order.fulfillmentStatus ==='FULFILLED' ?  */}
                <td><span className={ item.order.fulfillmentStatus === "NOT_FULFILLED" ? 'red' : 'green'}> { item.order.fulfillmentStatus === "NOT_FULFILLED" ? 'Non Traitée' : 'Traitée'} </span></td>
                
                
                <td><span>{item.order.shippingInfo?.title} </span></td>
                <td>{item.order.priceSummary.total.amount} {item.order.currency} </td>
                <td>
                    <button onClick={handleQuantityClick}>
                        <span>{totalProducts} {!isPopupOpen ? <FaChevronDown />: <FaChevronUp />}</span> 
                    </button>
                    {isPopupOpen && (
                        <Popup lineItems={item.order.lineItems} />
                    )}
                </td>
                <td >
                    <span className={item.delivered ? "green" : "red"}>{item.delivered ? "livrée" : "non livrée"}</span>
                </td>
                <td>wix</td>
        </tr>
    )
}

export default Row


//   // farward all the selected items to first delivery when the button is clicked
//   let deliverFn = (item)=>{
//     if(item.order.shippingInfo.title !=="Usine"){
//         let client = {
//             nom: `${item.order.billingInfo.contactDetails.firstName} ${item.order.billingInfo.contactDetails.lastName}`,
//             gouvernerat: `${item.order.shippingInfo.logistics.shippingDestination.address.city}`, // mch mawjoud
//             ville: `${item.order.shippingInfo.logistics.shippingDestination.address.city}`,
//             adresse: `${item.order.shippingInfo.logistics.shippingDestination.address.city} ${item.order.shippingInfo.logistics.shippingDestination.address.addressLine1} ${item.order.shippingInfo.logistics.shippingDestination.address.postalCode}`,
//             telephone: `${item.order.shippingInfo.logistics.shippingDestination.contactDetails.phone}`,
//             telephone2: "" 
//         }
//         // console.log('client:', client)
//         let des = ''
//         for (let i = 0; i <item.order.lineItems.length; i++){
//             des = `${des} | ${item.order.lineItems[i].productName.original}`
//             // products.push(pr)
//         }
//         let products = { // chihemou
//             article: des,
//             prix: Number(item.order.priceSummary.total.amount),
//             designation: des, 
//             nombreArticle: item.order.lineItems.reduce((acc, product) => acc + product.quantity, 0),  
//             commentaire: `${item.order.number}`,
//             nombreEchange: 0 
//         }
//         // console.log('products', products)
//         let delivery = {
//                 Client: client,
//                 Produit: products
//             }
//         return delivery
//     }else{
//         return false
//     }
// }

// let archiveHandler = () => {   
//     const selectedItems = items.filter(item => item.checked); // Filter checked items
//     console.log('to archive Items:', selectedItems);
//     selectedItems.forEach(async item => {
//         console.log(item.id)
//         // await updateDoc(doc(db, "orders" , `${item.id}`)), {
//         //     archived: true,
//         // }
//         await updateDoc(doc(db, "orders" , `${item.id}`),{
//             archived: true,
//         })
//     })
// }