import React from 'react'

const Popup = ({ variants }) => {
    // console.log('=========', variants )
    // console.log('=========', variants[0]   )
    // console.log('=========', variants[0].descriptionLines[0].plainText.original   )

    // variants.forEach(item => {
    //     item.descriptionLines.map((option, i) => 
    //         console.log('-', option.name.original)
    //     )
    // });
    // function extractImageName(url) {
    //     const regex = /\/v1\/([^#]+)/;
    //     const match = url.match(regex);
    //     return match ? match[1] : null;
    //   }
    console.log(Object.keys(variants[0].variant.variations))
      
    //   const url = "wix:image://v1/54b8db_347e1275f8ab4b91b499ba0f61202394~mv2.png#originWidth=1080&originHeight=1080";
    //   const imageName = extractImageName(url);
  return (
    <div className='popup'>
        <h3>Éléments</h3>
        <div className="elements">
            {
                variants.map((item, index) => (
                    <div className='element' key={index}>
                        <div>
                            <div><img alt={'product image' + item.variant.product.meta.title} src={item.variant.product.thumbnail}/></div>
                            <div className="disc">
                                <h4>{item.variant.product.meta.title}</h4>
                                <ul>
                                    {
                                        Object.keys(item.variant.variations).map((key, i) => (
                                            <li key={i}>{key === "default" ? `${key}` : `${key}: ${item.variant.variations[key]}`}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div>X{item.quantity}</div>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default Popup