import React, { useState } from 'react'
import PopupYoucan from './PopupYoucan';
import { FaChevronDown, FaChevronUp  } from "react-icons/fa";

const Row = ({item, onItemCheck }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false); 
    // if(item.data.ref==='170'){
    //     console.log(item)
    // }
    // if(item.data.ref==='139'){
    //     console.log(item)
    // }
    // console.log(item)
    // console.log('row data:', item);
    const totalProducts = item.data.variants.reduce((acc, product) => acc + product.quantity, 0);
    const handleQuantityClick = () => {
        console.log(item)
        setIsPopupOpen(prev=>!prev); // Show the popup
      };
    // const handleClosePopup = () => {
    //     setIsPopupOpen(false); // Hide the popup
    //   };
    // if()
    // let client = `${item.data.billingInfo.contactDetails.firstName} ${item.data.billingInfo.contactDetails.lastName}`
    
    return (
        <tr>
            <td><input checked={item.checked} type="checkbox" className="item-checkbox" id={'item-'+item.data.ref} onChange={() => onItemCheck(item.id)} /></td>
            <td><label  htmlFor={'item-'+item.data.ref}>{item.data.ref}</label></td>
                <td>{item.data.customer.full_name}</td>
                {/* { item.data.fulfillmentStatus ==='FULFILLED' ?  */}
                <td><span className={ !item.fulfilled ? 'red' : 'green'}> { !item.fulfilled ? 'Non Traitée' : 'Traitée'} </span></td>
                
                
                <td><span>{item.data.payment.gateway_type_text} </span></td>
                <td>{item.data.total} {item.data.currency}</td>
                <td>
                    <button onClick={handleQuantityClick}>
                        <span>{totalProducts} {!isPopupOpen ? <FaChevronDown />: <FaChevronUp />}</span> 
                    </button>
                    {isPopupOpen && (
                        <PopupYoucan variants={item.data.variants} />
                    )}
                </td>
                <td >
                    <span className={item.delivered ? "green" : "red"}>{item.delivered? "livrée" : "non livrée"}</span>
                </td>
                <td>youcan</td>
        </tr>
    )
}

export default Row