import React from "react";
import { Home, Login } from "./pages/Index";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRouteLogin from "./privateRoute/PrivateRouteLogin";
import { AuthProvider } from './contexts/AuthProvider'
import PrivateRoute from "./privateRoute/PrivateRoute";

function App() {
  return (
    <Router>
      <AuthProvider>
      <Routes>
          <Route element={< PrivateRouteLogin />} >
            <Route path="/login" element={<Login/>} />
          </Route>
          {/* <Route element={< PrivateRouteLogin />} >
            <Route path="/register" element={<Register/>} />
          </Route> */}
          <Route element={< PrivateRoute/>} >
            <Route exact path="/" element={<Home/>} />
          </Route>
      </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;